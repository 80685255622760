
import axios from 'axios'
const isDev = process.env.VUE_APP_URL_SERVER.includes('dev')
const isStaging = process.env.VUE_APP_URL_SERVER.includes('staging')

export default {
  async getQuizV2Flag () {
    const isUsingQuizV2 = localStorage.getItem('isUsingQuizV2') // return string or null
    let result
    try {
      const { data } = await axios.get('https://cdn.sekolah.mu/config/web/feature-flags.json')
      const { isSekolahmuUsingQuizV2Dev, isSekolahmuUsingQuizV2Staging, isSekolahmuUsingQuizV2Prod } = data

      if (isDev) {
        result = isSekolahmuUsingQuizV2Dev
      } else if (isStaging) {
        result = isSekolahmuUsingQuizV2Staging
      } else {
        result = isSekolahmuUsingQuizV2Prod
      }
    } catch (error) {
      console.error(error)
      if (isUsingQuizV2) {
        result = isUsingQuizV2 // Use the previously set feature flag value (true: Quiz V2, false, Quiz V1)
      } else {
        result = true //  Awlays use Quiz V2 as fallback
      }
    }
    return result
  }
}
