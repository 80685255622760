import Vue from 'vue'

import DefaultLayout from './DefaultLayout.vue'
import BlankLayout from './BlankLayout.vue'
import ActivityLayout from './ActivityLayout.vue'
import ActivityV2Layout from './ActivityV2Layout.vue'
import OverlayLayout from './OverlayLayout.vue'
import NoFooterLayout from './NoFooterLayout.vue'
import CheckoutLayout from './CheckoutLayout.vue'

Vue.component('default-layout', DefaultLayout)
Vue.component('blank-layout', BlankLayout)
Vue.component('activity-layout', ActivityLayout)
Vue.component('activity-v2-layout', ActivityV2Layout)
Vue.component('overlay-layout', OverlayLayout)
Vue.component('no-footer-layout', NoFooterLayout)
Vue.component('checkout-layout', CheckoutLayout)
