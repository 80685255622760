import PaymentHistoryAPI from '@/configs/api/payment/PaymentHistory'
import TransactionFilter from '@/functions/transactionFilter'
import invoiceStatus from '@/constants/paymentStatus.js'
const { bill, purchase } = TransactionFilter

const billStatus = [
  { icon: bill.all, icon_active: bill.allActive, id: 0, status: 'Semua', pick: true },
  { icon: bill.pending, icon_active: bill.pendingActive, id: 3, status: 'Belum Ada Pembayaran', pick: false },
  { icon: bill.activeDefault, icon_active: bill.active, id: 2, status: 'Aktif', pick: false },
  { icon: bill.paidoff, icon_active: bill.paidoffActive, id: 10, status: 'Lunas', pick: false },
  { icon: bill.failed, icon_active: bill.failedActive, id: 20, status: 'Tidak Melakukan Pembayaran', pick: false },
  { icon: bill.failed, icon_active: bill.failedActive, id: 21, status: 'Tagihan Tidak Dilanjutkan', pick: false }
]

const purchaseStatus = [
  { icon: purchase.all, icon_active: purchase.allActive, id: 0, status: 'Semua', pick: true },
  { icon: purchase.pending, icon_active: purchase.pendingActive, id: 2, status: 'Menunggu Pembayaran', pick: false },
  { icon: purchase.paid, icon_active: purchase.paidActive, id: 3, status: 'Dibayar', pick: false },
  { icon: purchase.success, icon_active: purchase.successActive, id: 10, status: 'Berhasil', pick: false },
  { icon: purchase.canceled, icon_active: purchase.canceledActive, id: 21, status: 'Dibatalkan', pick: false },
  { icon: purchase.failed, icon_active: purchase.failedActive, id: 20, status: 'Tidak Berhasil', pick: false },
  { icon: purchase.reject, icon_active: purchase.rejectActive, id: 23, status: 'Ditolak', pick: false },
  { icon: purchase.expired, icon_active: purchase.expiredActive, id: 22, status: 'Kedaluwarsa', pick: false },
  { icon: purchase.refund, icon_active: purchase.refundActive, id: 24, status: 'Dikembalikan', pick: false }
]

const paymentHistory = {
  namespaced: true,
  state: {
    listPaymentHistory: [],
    nextUrl: {},
    totalPage: 0,
    currentPage: 1,
    paymentDetail: {},
    billDetail: {},
    tabs: [
      { text: 'Tagihan', active: true },
      { text: 'Pembelian', active: false }
    ],
    activeTab: 'Tagihan',
    paymentStatusList: billStatus
  },
  mutations: {
    CHANGE_LIST_PAYMENT_HISTORY (state, payload) {
      state.listPaymentHistory = payload
    },
    CHANGE_TOTAL_PAGE (state, payload) {
      state.totalPage = payload
    },
    CHANGE_CURRENT_PAGE (state, payload) {
      state.currentPage = payload
    },
    CHANGE_PAYMENT_DETAIL (state, payload) {
      state.paymentDetail = payload
    },
    CHANGE_BILL_DETAIL (state, payload) {
      state.billDetail = payload
    },
    SET_TABS (state, payload) {
      state.tabs = payload
    },
    CHANGE_ACTIVE_TAB (state, payload) {
      state.activeTab = payload
      if (payload === 'Tagihan') {
        state.paymentStatusList = billStatus
      } else {
        state.paymentStatusList = purchaseStatus
      }
    },
    RESET_TABS (state) {
      state.activeTab = 'Tagihan'
      state.tabs = [
        {
          text: 'Tagihan',
          active: true
        },
        {
          text: 'Pembelian',
          active: false
        }
      ]
      state.paymentStatusList = billStatus
    },
    CHANGE_PAYMENT_FILTER (state, payload) {
      if (payload === 'Tagihan') {
        state.paymentStatusList = billStatus
      } else {
        state.paymentStatusList = purchaseStatus
      }
    },
    CHOOSE_STATUS_FILTER (state, payload) {
      state.paymentStatusList = payload
    }
  },
  actions: {
    // asynchronus fetch API data
    async getPaymentHistoryListV4 ({ rootState, commit }, payload) {
      let result
      if (payload.activeTab.toLowerCase() === 'pembelian') {
        result = await PaymentHistoryAPI.postV2(`/v4/list/${payload.query.current_page}/10`, { token: rootState.storage.accessToken, data: payload.data })
      } else {
        result = await PaymentHistoryAPI.postV2(`/billing/list/${payload.query.current_page}/10`, { token: rootState.storage.accessToken, data: payload.data })
      }
      if (result.status === 200 || result.message === 'OK') {
        commit('CHANGE_LIST_PAYMENT_HISTORY', result.data)
        commit('CHANGE_TOTAL_PAGE', result.total_page)
      } else {
        commit('CHANGE_LIST_PAYMENT_HISTORY', [])
        commit('CHANGE_TOTAL_PAGE', 0)
        commit('CHANGE_CURRENT_PAGE', 1)
      }
    },
    async getPaymentDetailV4 ({ rootState, commit }, payload) {
      const result = await PaymentHistoryAPI.getV2(`/v4/detail/${payload.id}`, { token: rootState.storage.accessToken })
      if (result.status === 200 || result.message === 'OK') {
        commit('CHANGE_PAYMENT_DETAIL', result.data)
        return result
      } else {
        return result
      }
    },
    async GET_PAYMENT_BILL_DETAIL ({ rootState, commit }, payload) {
      const result = await PaymentHistoryAPI.getV2(`/billing/detail/${payload.id}`, { token: rootState.storage.accessToken })
      if (result.status === 200 || result.message === 'OK') {
        commit('CHANGE_BILL_DETAIL', result.data)
        return result
      } else {
        return result
      }
    }
  },
  getters: {
    nextUrlStatus: state => {
      return state.nextUrl.status
    },
    // Payment Detail Section
    isFree (state) {
      return state.paymentDetail.payment_gateway === 'FREE'
    },
    isPrakerja (state) {
      return state.paymentDetail.payment_gateway === 'PRAKERJA'
    },
    isProgram (state) {
      return state.paymentDetail.product.type === 'program'
    },
    isLearningPackage (state) {
      return state.paymentDetail.product.type === 'learning-package'
    },
    isLearningPackageV2 (state) {
      return state.paymentDetail.product.type === 'learning-package-v2'
    },
    isPaymentRequested (state) {
      return state.paymentDetail.status === invoiceStatus.PAYMENT_REQUESTED
    },
    isPaymentFailed (state) {
      return state.paymentDetail.status === invoiceStatus.FAILED || state.paymentDetail.status === invoiceStatus.EXPIRED
    },
    isPaymentSuccess (state) {
      return state.paymentDetail.status === invoiceStatus.SUCCESS
    },
    isVAMethod (state) {
      return state.paymentDetail.payment.category_name === 'Virtual Account'
    },
    isRetailMethod (state) {
      return state.paymentDetail.payment.category_name === 'Retail Outlet'
    },
    isWalletMethod (state) {
      return state.paymentDetail.payment.category_name.toLowerCase() === 'ewallet'
    },
    getProductConfig (state) {
      const reducedList = state.paymentDetail.product.items.reduce((accumulator, currentValue) => {
        return accumulator.concat(currentValue.content)
      }, [])

      const filteredList = reducedList.filter(item => item.percentage)

      return filteredList.map((item) => {
        return {
          label: `${item.percentage * 100}% ${item.name}`,
          amount: item.price * item.percentage
        }
      })
    }
  }
}

export default paymentHistory
